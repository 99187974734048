
import { computed, defineComponent } from 'vue';
import TradistIcon from '../Icons/TradistIcon.vue';
import HollowXIcon from '../Icons/HollowXIcon.vue';

export default defineComponent({
	name: 'ShareDropdown',
	props: {
		twitterLink: {
			type: String,
			default: '',
		},
		facebookLink: {
			type: String,
			default: '',
		},
		instagramLink: {
			type: String,
			default: '',
		},
		linkedinLink: {
			type: String,
			default: '',
		},
	},
	setup(props, { emit }) {
		const openLink = (url) => {
			window.open(url, '_blank');
		};

		const copyToClipboard = () => {
			navigator.clipboard.writeText('hebele hubele bum bum');
		};

		const linkOptions = computed(() => {
			return [
				{ text: 'Twitter', onClick: () => openLink(props.twitterLink) },
				{ text: 'Instagram', onClick: () => openLink(props.instagramLink) },
				{ text: 'Facebook', onClick: () => openLink(props.facebookLink) },
				{ text: 'LinkedIn', onClick: () => openLink(props.linkedinLink) },
				{ text: 'Copy link', onClick: copyToClipboard },
			];
		});

		const handleClose = () => {
			emit('close');
		};

		return {
			linkOptions,
			handleClose,
		};
	},
	components: { TradistIcon, HollowXIcon },
});
