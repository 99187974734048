import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f562fffc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "share-dropdown" }
const _hoisted_2 = { class: "share-dropdown__header" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TradistIcon = _resolveComponent("TradistIcon")!
  const _component_HollowXIcon = _resolveComponent("HollowXIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TradistIcon, { class: "share-dropdown__header--logo" }),
      _createVNode(_component_HollowXIcon, {
        class: "share-dropdown__header--close",
        onClick: _ctx.handleClose
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkOptions, (link) => {
        return (_openBlock(), _createElementBlock("a", {
          class: "share-dropdown__link",
          onClick: link.onClick,
          key: `link-${link.text}`
        }, _toDisplayString(link.text), 9, _hoisted_3))
      }), 128))
    ])
  ]))
}