<template>
	<svg
		width="40px"
		height="40px"
		viewBox="0 0 40 40"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-166.000000, -334.000000)">
				<g transform="translate(74.000000, 246.000000)">
					<g transform="translate(92.000000, 88.000000)">
						<g>
							<path
								class="c1-primary stroke-primary"
								d="M19.998347,0.249994535 C25.376891,0.285558184 30.3049155,2.35276654 34.0063625,6.07561269 C37.7100899,9.80103795 39.75,14.7460722 39.75,20 C39.75,25.2545336 37.7100929,30.1998745 34.0063836,33.9252816 C30.3047751,37.647684 25.3772385,39.7159398 20.1312256,39.7490845 C14.7252866,39.75 9.76549216,37.6962329 6.03521473,33.9653527 C2.30437018,30.2354123 0.25,25.2753163 0.25,20 C0.25,14.7246722 2.30438588,9.76547611 6.03523615,6.03462584 C9.7654817,2.30438029 14.725279,0.25 19.998347,0.249994535 Z"
								stroke-width="0.5"
								fill-opacity="0.6"
							></path>
							<path d="" fill="#000000" fill-rule="nonzero"></path>
						</g>
						<path
							d="M28.5517241,19.494918 L21.6731462,12 L21.6731462,16.4716039 L20.2107018,16.4716039 C15.6760002,16.4716039 12,20.1243255 12,24.6303109 L12,27 L12.6495824,26.2926695 C14.8580869,23.8882225 17.9830028,22.5182322 21.2592016,22.5182322 L21.6731462,22.5182322 L21.6731462,26.9898361 L28.5517241,19.494918 Z"
							class="stroke-primary"
							stroke-width="0.810566"
							fill="#FFFFFF"
							fill-rule="nonzero"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
