
import { computed, defineComponent, ref, watch } from 'vue';
import BaseCard from '../components/Base/BaseCard.vue';
import { useUser } from '../composables/useUser';
import ShareIcon from '../components/Icons/ShareIcon.vue';
import ShareDropdown from '../components/Dropdowns/ShareDropdown.vue';
import { useDropdown } from '../composables/useDropdown';
import { cryptoWallet } from '@tradist/blockchain/frontend';
import { apiService } from '../services/ApiService';
import BaseButton from '../components/Base/BaseButton.vue';
import { config } from '../config';
import { useRoute } from 'vue-router';
import { useLoader } from '../composables/useLoader';

export default defineComponent({
	setup() {
		const { user } = useUser();
		const route = useRoute();
		const { openLoader, closeLoader } = useLoader();

		const { isShareDropdownOpen, openShareDropdown, closeShareDropdown } =
			useDropdown('Share');

		const doesUserHaveShareLinks = computed(() => {
			return false;
		});

		const items = ref([]);
		const pagination = {
			count: 0,
			page: 0,
		};

		const getItems = async () => {
			try {
				openLoader();
				const apiInstance =
					route.name === 'my-items'
						? (page) => apiService.getMyItems(page)
						: (page) => apiService.getUserItems(route.params.address, page);

				const {
					data: { tokens, count },
				} = (await apiInstance(pagination.page)) as {
					data: { tokens: []; count: number };
				};
				items.value = [...items.value, ...tokens];
				pagination.count = count;
			} finally {
				closeLoader();
			}
		};

		(async function initializeItems() {
			getItems();
		})();

		watch(route, () => {
			items.value = [];
			getItems();
		});

		const handleMoreButton = () => {
			pagination.page++;
			getItems();
		};

		const hasMorePages = computed(() => {
			return pagination.count > items.value.length;
		});

		const userAddress = computed(() => {
			return route.name === 'my-items'
				? cryptoWallet.address
				: route.params.address;
		});

		return {
			config,
			user,
			doesUserHaveShareLinks,
			isShareDropdownOpen,
			openShareDropdown,
			closeShareDropdown,
			items,
			handleMoreButton,
			hasMorePages,
			userAddress,
		};
	},
	components: { BaseCard, ShareIcon, ShareDropdown, BaseButton },
});
